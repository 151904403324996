
:root{
--add-btn-color: #ddc77d;
--add-btn-color-hover: #b49e55;
--add-btn-color-disabled: #eee1b5;
--menu-color: #458500;
--table-margin-side: 90px;
--product-image-height: 160px;
--product-padding: 20px;

--hiding-size: 60px;
}

/* html {
	scroll-behavior: smooth;
} */

.loader {
  width: 128px;
  height: 128px;
  border: 16px solid var(--menu-color);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.loading{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


a.anchor {
    display: block;
    position: relative;
    top: -80px;
    visibility: hidden;
}

.navbar{
  margin-top: var(--hiding-size);
  margin-left: 0px;
  position: sticky;
  top:0;
  width: 100%;
  z-index: 1005;
  display: flex;
  flex-direction: column;
}

.navbar-container {
  display: flex;
  padding: 10px;
  padding-top: calc( var(--hiding-size) + 10px );
  margin-top: calc( 0px - var(--hiding-size) );


  text-align: left;
  background-color: var(--menu-color);
  padding-left: calc( var(--table-margin-side) + 20px );
  padding-right: calc( var(--table-margin-side) + 20px );
  height: 40px;
  color: rgb(255, 255, 255);
  position: sticky;

  z-index: 1000;
}
#hiding-header{
  width: 100%;
  position: absolute;
  top:0;
  left:0;
}
#hiding-header-message{
  position: absolute;
  top:50px;
  left:0;
  margin-left: calc( var(--table-margin-side) + 20px );
  margin-right: calc( var(--table-margin-side) + 20px );
}
#hiding-header-message p{
  border-radius: 5px;
  padding:5px;
  color:#000;
  background-color: #b3e285;
}
#hiding-header-message b{
  color:#ff2525;
}

#hiding-header-container{
  margin: 10px;
  margin-left: calc( var(--table-margin-side) + 20px );
  margin-right: calc( var(--table-margin-side) + 20px );
  display: flex;
  justify-content: flex-end;
  float:none;
}
#contacts-lbl{
  height: 100%;
  float:right;
}
#contacts-lbl h2{
  font-size: 18px;
}
.h-push{
  margin-left: auto;
}

#logo-link{
  display: flex;
  text-decoration: none;
  color: white;
  flex-grow: 1;
}

#logo-link h2{
  margin: 0;
  margin-left:10px;
}

#logo{
  padding-left: 0px;
  margin-left: -5px;

  height: 50px;
}

.center-div{
  display: flex;
  align-items: center;
}
.h100{
  height: 100%;
}

.menu-button img{
  float: left;
  height: 40px;
  padding-right: 10px;
  padding-left: 10px;
}

.menu-button{
  float: left;
  padding:0;
  padding-right:10px;
  width:40px;
  margin:0;
}
.menu-button img{
  padding:0px;
}


.navbar-container input[type="checkbox"] {
  position: absolute;
  height: 40px;
  margin:0;
  padding:0;
  width: 35px;
  z-index: 5;
  opacity: 0;
}

.hamburger-lines {
  display: block;
  margin-left:5px;
  margin-top:8px;
  height: 24px;
  width: 30px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #333;
}

.hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.btn{
    margin-left: 10px;
    display: flex;
    align-items: center;


    padding: 10px;
    border: none;

    color: #FFF;
    font-weight: bold;
    background-color: #d25700;
    font-size: 16px;

    cursor:pointer;
}
.btn:hover{
    background-color: #973f00;
}
.btn:disabled{
    background-color: #818181;
}


.cover{
    top:0;
    left:0;
    background-color: rgba(0, 0, 0, 0.176);
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 20;
    transition: background-color 0.5s ease-in-out;
}

.over-cover{
    z-index: 22;
}

.cover-gone{
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0);
    transition: visibility 0.5s, background-color 0.5s ease-in-out;
}


.navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  transform: translateX(6px) rotate(45deg);
}

.navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
}

.navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  transform: translateX(6px) rotate(-45deg);
}

.table-wrapper{
  margin:40px;
  margin-top:30px;

  margin-left: var(--table-margin-side);
  margin-right: var(--table-margin-side);
}


/*--------------- Buy controll -----------------*/

.menu-circle-button{
  margin-left: 10px;
  position: relative;
  float:right;
  height:40px;
}
.menu-circle-button>a{
  height:40px;
  width:40px;
  border-radius: 50%;
  background-color: var(--add-btn-color);
  border:none;
  cursor:pointer;
  display:flex;
  justify-content: center;
  align-items: center;
}
.icon {
  font-size: 30px;
  fill: currentColor;
  flex-shrink: 0;
  height: 1em;
  overflow: hidden;
  vertical-align: -0.2em;
  width: 1em;
}
.menu-circle-button>a>img{
  height: 60%;
}

.buy-quantity{
  right: 0;
  top: 0;
  position:absolute;
  background-color: #e05d5d;
  color: #fff;
  font-size: 11px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}


#olx-contact a{
  background-color: #ffffff;
}
#olx-contact img{
  height: 80%;
}
#top-title{
  margin-right: auto;
}
.about-link{
  font-size: 18px;
}
.about-link a{
  font-weight: bold;
  color:#fff;
  text-decoration: none;
  text-align: end;
}

/*---------- inc dec add buttons -------------*/

.inc-dec-item{
  position: absolute;
  right:var(--product-padding);
}
.inc-dec-btn{
  border-radius: 50%;
  background-color: var(--add-btn-color);
  border:none;
  width: 30px;
  height: 30px;
  padding:0px;
  font-size: 20px;
  display:flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}
.inc-dec-btn:hover{
  background-color: var(--add-btn-color-hover);
}
.inc-dec-btn:disabled{
  background-color: var(--add-btn-color-disabled);
}

.item-cart-quantity{
  text-align: center;
  padding:10px;
}

/*------------*/

.category-wrapper{
  width: 100%;
  margin-bottom:40px;
  flex-wrap: wrap; 
  display: flex;
}

.category{
  width: 100%;
  position: sticky;
  top:60px;
  min-height: 10px;
  background-color: #f0f0f0;
  color: #5c5c5c;
  padding: 10px;
  padding-left: 20px;
  font-size: 18px;
  z-index: 1000;
}
.category h3{
  margin:0;
}

.transparent-link{
  font-size: 14px;
  color: #1976d2;
  text-decoration: none;
  padding-top: var(--product-image-height);
  padding-bottom:40px;
}

.product-image{
  pointer-events: none;
  position:absolute;
  height: calc( var(--product-image-height) - 20px);
}

.product-wrapper{
  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  border-width: 1px;
  border-style: solid;
  /* border-color: rgb(224, 224, 224); */
  padding: var(--product-padding);
  width: 25%;
}

.product-bottom-wrapper{
  position:absolute;
  bottom: 0;

  width: 100%;
}

.product-bottom{
  padding: var(--product-padding);
  position: relative;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.product-price{
  margin-right: var(--product-padding);
}

.lowQuantity{
  color: #916302;
}
.highQuantity{
  color: #197e08
}

.out-of-stock{
  opacity: 0.3;
}

.product-link{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 1400px) {
  .product-wrapper {
    width: 33.3333%;
  }
}

@media only screen and (max-width: 1200px) {
  .product-wrapper {
    width: 50%;
  }
}

@media only screen and (max-width: 1000px) {
  
  #hiding-header-container h2 {
    font-size: 18px;
  }
  :root{
    --product-image-height: 120px;
    --product-padding: 10px;
  }
}

.text-page{
    margin-left: 20px;
}

.search-box{
  position: relative;
  display: flex;
  flex-grow: 1;
}
.search-box button{
  position:absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  background-color: transparent;
  border: none;
}
.search-icon{
  position:absolute;
  left: 10px;
  top: 6px;
}
.search-input {
  width: 0;
  flex-grow: 1;
  border: none;
  border-radius: 5px;
  background-color: white;
  padding-left: 40px;
  padding-right: 50px;
}
.suggestion{
  border: 1px solid rgb(136, 136, 136);
  border-radius: 5px;
  top: calc(100% + 5px);
  width: 100%;
  position: absolute;
  background-color: white;
  color: #444;
	box-shadow: 2px 2px 3px #999;
}
.suggestion a{
  text-decoration: none;
  color: black;
  display: block;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.sugg-selected{
  background-color: rgb(144, 144, 230);
}
