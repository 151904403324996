.cart-item{
    border-bottom: 2px solid #458500;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: 20px;
}

.name-row{
    position: relative;
    display: flex;
    margin-bottom: 20px;
    align-items: start;
}
.name-row img{
  height:80px;
}

.del-item{
    border-radius: 5px;
    margin-left: auto;
    border:none;
    background-color: #ffffff;
    padding:5px;
    cursor:pointer;
    z-index: 1;
}
.del-item:hover{
    background-color: #dddddd;
}
.del-item img{
    height: 20px;
}

.price-row{
    display: flex;
    justify-content: right;
    align-items: center;
}
.inc-dec-cart{
    display: flex;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
}


.after-table{
}

.order-block{
    margin-top: 1em;
}

.sum-group {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.sum-group img{
    height: 40px;
}

.sum-group div{
    height: 20px;
    padding: 10px;
    color: #FFF;
    font-weight: bold;

    background-color: #458500;
}


.empty-cart img{
    height: 90px;
    margin: 20px;
}
.empty-cart{
      text-align: center;
    font-size:20px;
}


.phone-group{
    display: flex;
    padding-top: 10px;
    padding-bottom: 340px;
}

#phone{
  font-size: 18px;
  width: 160px;
}

#qr_btn{
    margin-top: 0px;
    background-color: #68503f;
}
#qr_btn:hover{
    background-color: #47372b;
}
.qr-code-img{
    margin-bottom: 50px;
}
/* @media screen and (max-width: 800px) {
    #qr_btn{
        display: none;
    }
} */

#viber {
  background-color: #704381;
}

.circle-logo{
  margin-left: 10px;
  position: relative;
  float:right;
  height:40px;
  width:40px;
  border-radius: 50%;
  border:none;
  display:flex;
  justify-content: center;
  align-items: center;
}
.circle-logo img{
  height: 60%;
}
#telegram{
  height:40px;
  width:40px;
  padding-left: 10px;
  padding-right: 10px;
}