

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:var(--menu-color);
	border-radius:50px;
	border: none;
	box-shadow: 2px 2px 3px #999;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cat-group{
	padding-left: 20px;
}
.menu-items a,
.menu-items span {
	display: block;
	padding: 10px;
	font-size: 18px;

	text-decoration: none;

	border: 1px solid #f0f0f0;
	color: #444;
}

.menu-items a:hover {
  color: #147911;
  background-color: #f0f0f0;
}

.selected-cat{
  background-color: #c2dfa4;
}

a.selected-cat:hover {
  background-color: #b6d199;
}

.menu-items span {
  background-color: #e0e0e0;
	border: 1px solid #f0f0f0;
  cursor: pointer;
}
.menu-items span:hover {
  color: #147911;
  background-color: #d0d0d0;
}

.childs{
  transition: height 0.5s;
  overflow: hidden;
}

.menu-items {
  margin-left: 10px;
  margin-right: 10px;
}



@media only screen and (max-width: 750px) {
.float{
	width:50px;
	height:50px;
}
.float svg{
  height: 30px;
}
}


.additional-viber{
  width: 200px;
  border-radius: 10px;
  padding-right: 10px;

  display: flex;
  height: 40px;
  align-items: center;
  gap:10px;
  text-decoration:none;
  color: white;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out;
}

.items-table{
  display: flex;
  padding-top:10px;
  padding-right: calc(var(--table-margin-side) + 20px);
}

@media only screen and (max-width: 1000px) {
  .menu-items {
    position: fixed;
    left: 0px;
    top: 0px;
    pointer-events: auto;
    margin: 0;
    background: #fff;
    width: 300px;
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    transform: translate(0);
    transition: transform 0.5s ease-in-out;
    box-shadow: 2px 2px 10px #00000042;
    z-index: 1230;

    overflow: auto;
    height: 100vh;
  }

  .menu-items.closed {
    transform: translateX(calc(-100% - 14px));
  }

  .additional-viber {
    display: none;
  }
  :root{
    --table-margin-side: 0px;
  }

  .item-table-content{
    padding-left: 10px;
  }

  .items-table {
    display: flex;
    padding-top: 10px;
    padding-right: 10px;
  }

}

@media only screen and (min-width: 1000px) {
  .menu-items{
    position: unset;
    width: 300px;
    flex-shrink: 0;
  }
  .show-categories-btn{
    display: none;
  }
}

