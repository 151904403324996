.item-details-content{
    width: min-content;
    min-width: 70%;
    margin: auto;
    padding: 20px;
    background-color: #ffff;
    margin-bottom: 200px;
}

.item-description{
    display: flex;
}



.item-description img{
    height: 20em;
}

@media only screen and (max-width: 800px) {
    .item-description img {
        width: 100%;
        height: auto;
    }

    .item-description {
        flex-wrap: wrap;
    }
}

.description{
    margin-bottom: 2em;
}

.details-bottom{
    margin-top:1em;
}

.add-to-card{
    display: block;
}

.details-price {
    font-size: large;
    margin-left: auto;
    margin-right: 20px;
    font-weight: bold;
    color: #458500;
}