#about-page li{
    padding-bottom: 20px;
}

.photo{
    float: left;
    margin-right: 60px;
    margin-bottom: 40px;
    height: 300px;
}


@media only screen and (max-width: 750px) {
.photo{
    float: unset;
    display: block;
}
}