.info-show-small{
    display: none;
}

.info-menu-content{
    padding-left: 5px;
    display: flex;
    gap:1px;
    justify-content: flex-end;
}

.info-menu-content>a{
    border-radius: 10px;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;

    display: flex;
    height: 40px;
    align-items: center;
    gap:10px;
    text-decoration:none;
    color: white;
    font-weight: bold;
    transition: background-color 0.2s ease-in-out;
}

.info-menu-content>a:hover{
    transition: background-color 0.2s ease-in-out;
    background-color: #00000014;
}
#viber-in-menu {
    display: none;
}

@media only screen and (max-width: 1200px) {
  #viber-in-menu{
      display: flex;
  }


.info-show-small{
    display: unset;
}

.info-btn{
    font-size: 20px;
    font-weight: bold;
    color: white;
    border:none;
    background-color: var(--menu-color);
    transition: 0.3s ease-in-out;
}
.info-btn.checked{
    background-color: white;
    color: rgb(80, 80, 80);
}

.info-menu {
    margin-top:50px;
    margin-right:-12px;
    pointer-events: none;
    position: absolute;
    z-index: 21;
    overflow: hidden;
    padding-left: 2px;
    padding-right: 12px;
    padding-bottom: 12px;
}

.info-menu-content {
    padding: 10px;
    display: block;
    pointer-events: auto;
    background-color: white;
    color: black;
    margin-block-start: 0;

    transform: translate(0);
    transition: transform 0.3s ease-in-out;
    width:250px;
    box-shadow: 2px 2px 10px #00000042;
}
.hidden-top{
    transform: translateY(calc(-100% - 14px));
}

.info-menu-content>.no-icon{
    padding-left:60px;
}
.info-menu-content>a{
    color: rgb(80, 80, 80);
}

} /* media */

.img-wrapper{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #704381;
}

.olx-menu-item .img-wrapper{
    background-color: #ffffff;
    border: 1px solid rgb(163, 163, 163);
    box-sizing: border-box;
}
.dbg-menu-item .img-wrapper{
    background-color: #ffffff;
    border: 1px solid rgb(163, 163, 163);
    box-sizing: border-box;
}

.olx-menu-item img{
    vertical-align: middle;
    height: 30px;
}

.viber-menu-item img{
    vertical-align: middle;
    height: 24px;
}
